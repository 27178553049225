.wrapper {
  display: flex;
  min-height: 300px;
  .menu {
    width: 175px;
    background: rgba(112, 112, 120, 0.2);
    border-radius: 8px 0 0 8px;
    h2 {
      font-size: 18px;
      font-weight: 500;
      color: #fff;
      margin: 15px 0 10px 15px;
    }
    .menus {
      background: none;
      li {
        background: none;
        margin-inline: 6px;
        width: calc(100% - 12px);
        color: #fff;
        &:hover {
          background: #10a37f !important;
          color: #fff !important;
        }
      }
      :global {
        .ant-menu-item-active {
          background: #10a37f;
          color: #fff;
        }
      }
    }
  }
  .content {
    flex: 1;
    padding: 15px 20px 30px 20px;
  }
}