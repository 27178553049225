@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&display=swap");

:root {
  /* Main colors */
  --primary-color:             #343541;

  /* navigation */
  --left-nav-color:            #000;

  /* typography */
  --primary-font:              "Roboto", sans-serif;
  --text-primary:              #ececf1;
  --text-light-heading:        #8c8c8c;

  /* margins and padding */
  --base-padding: 12px;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--primary-color);
  font-family: var(--primary-font);
}

body,
html {
  height: 100%;
}

* {
  margin: 0;
  padding: 0;
  outline: 0;
}

#root {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
