.chat-wrapper {
  display: flex;
  height: 100%;
  overflow: hidden;
  .chat-area {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    padding-bottom: 0px;
    transition: all 0.3s ease-in-out;
    &.open {
      margin-right: 470px;
    }
    .chat-scroll {
      display: flex;
      flex-direction: column;
      overflow-y: auto;
      height: 100%;
      flex: 1;
      padding-top: 80px;
    }
  }
  .chat-input {
    padding: 15px 0;
    position: relative;
  }
  .preview {
    width: calc(470px - 40px);
    background: #2e2f3a;
    margin-top: 56px;
    height: calc(100% - 96px);
    padding: 20px;
    position: fixed;
    right: -470px;
    transition: all 0.3s ease-in-out;
    &.open {
      right: 0;
    }
    .preview-area {
      background: #d8d8d8;
      height: calc(100% - 40px);
      padding: 20px;
      text-align: center;
      h4 {
        font-size: 20px;
        font-weight: 600;
        text-align: left;
        margin-bottom: 20px;
      }
      img {
        // width: 100%;
        // margin: 0 auto;
        height: 90%;
      }
    }
  }
  .default-message {
    display: flex;
    height: 100%;
    align-items: center;
    .message {
      max-width: 768px;
      margin: 0 auto;
    }
    font-size: 16px;
    color: var(--text-primary);
    margin-bottom: 20px;
    line-height: 28px;
    white-space: pre-wrap;
  }
}
