.auth-layout {
  width: calc(100% - 50px);
  height: calc(100% - 50px);
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f9fafb;
  padding: 25px;
  .container {
    background: #fff;
    border-radius: 12px;
    padding: 32px 40px;
    box-shadow: 0px 2px 4px -2px rgba(16, 24, 40, 0.06),
      0px 4px 8px -2px rgba(16, 24, 40, 0.1);
    max-width: calc(480px - 80px);
    width: 100%;
    .logo {
      text-align: center;
      margin-bottom: 20px;
      img {
        width: 50px;
      }
    }
  }
}
