.head {
  background: rgba(52, 53, 65, 0.9);
  height: calc(56px - 32px);
  padding: 16px;
  display: flex;
  align-items: center;
  color: #fff;
  justify-content: space-between;
  position: fixed;
  top: 0;
  width: calc(100% - 260px - var(--base-padding) * 2 - 8px);
  // margin-bottom: 25px;
  .hamburger {
    padding: 0;
    height: auto;
    background: none;
    border: none;
    outline: none !important;
    box-shadow: none !important;
    padding: 7px 7px;
    height: 34px;
    border-radius: 4px !important;
    &:hover {
      background: red;
    }
    & > div {
      display: none;
    }
    &:hover {
      background: rgba(35, 36, 45, 0.9);
    }
    // border: solid 1px var(--text-primary);
  }
  .new-chat {
    margin-left: 10px;
  }
  .nav {
    background: none;
    line-height: 30px;
    color: var(--text-primary);
    flex: 1;
    justify-content: flex-end;
    border: none;
    li {
      // border: solid 1px var(--text-light-heading);
      &:after {
        display: none !important;
      }
      border: solid 1px transparent;
      border-radius: 6px !important;

      &:global(.ant-menu-submenu-selected) {
        :global {
          .ant-menu-title-content {
            color: var(--text-primary) !important;
          }
        }
      }
      &:global(.ant-menu-item-selected) {
        // border: solid 1px var(--text-light-heading);
        outline: none !important;
        span {
          color: var(--text-primary);
        }
        :global {
          .ant-menu-title-content {
            color: var(--text-primary) !important;
          }
        }
      }
      &:hover {
        border: solid 1px var(--text-light-heading);
        background: #4d4e58;
        span {
          color: var(--text-primary);
        }
        :global {
          .ant-menu-title-content {
            color: var(--text-primary) !important;
          }
        }
      }
    }
  }
}

.download-form {
  margin-top: 15px;
  label {
    font-weight: 600;
    font-size: 16px !important;
    display: flex;
    margin-bottom: 10px;
  }
  .card {
    background: #ebebeb;
    :global {
      .ant-card-body {
        padding: 10px 15px;
      }
    }
  }
  button {
    // margin-top: 10px;
    width: 100%;
  }
  .published {
    color: green;
    font-weight: 500;
    margin-top: 10px;
  }
  p {
    margin-bottom: 15px;
  }
}

.publish-modal {
  .disable-btn {
    padding: 0;
    color: #585858;
    span {
      text-decoration: underline;
    }
    &:hover {
      color: #585858 !important;
    }
  }
  :global {
    .ant-modal-footer {
      display: flex;
      justify-content: space-between;
    }
  }
}