.email {
  h1 {
    font-size: 34px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    text-align: center;
    margin-bottom: 14px;
    &.signup-heading {
      margin-bottom: 25px;
    }
  }
  p {
    text-align: center;
    color: #475467;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 24px;
  }
}
