.have-account {
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  color: #475467;
  a {
    margin-left: 3px;
    color: #10a37f;
    font-weight: 500;
    &:hover {
      color: #000;
    }
  }
}