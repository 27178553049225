.chat-input {
  max-width: 766px;
  margin: 0 auto;
  padding: 0 15px;
  position: relative;
  .input-wrapper {
    border: solid 1px rgb(133 133 146) !important;
    border-radius: 15px;
    .inner {
      display: flex;
      align-items: flex-end;
      .upload {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50px;
        height: 49px;
        border-radius: 15px 0 0 15px;
        button {
          padding: 5px;
        }
      }
    }
    .image-wrapper {
      display: flex;
      margin: 10px 10px 10px 10px;
      .image {
        width: 60px;
        height: 60px;
        position: relative;
        &:hover {
          .close {
            display: block;
          }
        }
        .close {
          position: absolute;
          right: -6px;
          top: -6px;
          border: solid 1px #fff;
          border-radius: 100%;
          color: #fff;
          display: none;
          cursor: pointer;
          z-index: 99;
        }
        img {
          width: 100%;
          height: 100%;
          border-radius: 10px;
          border: solid 1px rgba(86,88,105,1);
          object-fit: cover;
        }
      }
    }
  }

  input {
    height: 52px;
    background: none;
    border: solid 1px rgb(133 133 146) !important;
    border-radius: 15px;
    color: #fff;
    outline: none !important;
    box-shadow: none !important;
    padding: 4px 20px;
    font-size: 16px;
    &::placeholder {
      color: #979797;
    }
  }
  textarea {
    // height: 52px;
    background: none;
    border: none;    
    color: #fff;
    outline: none !important;
    box-shadow: none !important;
    // padding: 4px 20px;
    padding: 14px 20px 14px 0px;
    font-size: 16px;
    min-height: 50px;
    max-height: 100px;
    line-height: 22px;
    &::placeholder {
      color: #979797;
      // padding-top: 5px;
    }
  }
}