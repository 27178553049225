.form {
  label {
    color: #fff !important;
    font-size: 14px !important;
  }
  input {
    border-radius: 4px;
    background: rgba(255, 255, 255, 0.1);
    border: solid 1px rgba(255, 255, 255, 0.2);
    height: 38px;
    color: #fff;
    &::placeholder {
      color: #9c9c9c;
    }
  }
  .delete-button {
    position: absolute;
    right: 20px;
    font-size: 16px;
    color: red;
    z-index: 99;
  }
  .add-btn {
    float: right; 
    padding-left: 0;
    padding-right: 0;
    color: #4bd8b5;
    &:hover {
      color: #95e7d2 !important;
    }
  }
  :global {
    .ant-divider {
      border-block-start: 1px dashed rgba(255, 255, 255, 0.1);
    }
  }
}
