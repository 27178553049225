.reset-password {
  h1 {
    font-size: 34px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    text-align: center;
    margin-bottom: 34px;
  }
  .forgot {
    margin-bottom: 10px;
    margin-top: -10px;
    text-align: right;
    a {
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      color: #10a37f;
      &:hover {
        color: #000;
      }
    }
  }
}
