.chat-row {
  .chat-column {
    max-width: 768px;
    margin: 0 auto;
    display: flex;
    gap: 15px;
    align-items: flex-start;
    margin-bottom: 40px;
    padding: 0 15px;
    &:last-child {
      margin-bottom: 20px;
    }
    .avatar {
      z-index: -1;
    }
    .chat-message {
      width: calc(100% - 47px);
      .sender {
        font-size: 17px;
        font-weight: 600;
        color: #fff;
        margin-bottom: 10px;
      }
      .message {
        font-size: 16px;
        color: var(--text-primary);
        margin-bottom: 20px;
        // white-space: pre-wrap;
        p {
          font-size: 16px;
          color: var(--text-primary);
          margin-bottom: 20px;
          line-height: 28px;
        }

        ol, ul {
          margin-left: 20px;
          li {
            line-height: 32px;
            margin-bottom: 20px;
            ul {
              margin-top: 20px;
            }
            p {
              margin-bottom: 0;
            }
            ::marker {
              display: none;
            }
          }
        }
        pre {
          margin-bottom: 20px;
          code {
            overflow-x: auto;
            line-height: 26px;
          }
        }
        :global {
          .chat-image {
            max-width: 500px;
            img {
              width: 100%;
            }
          }
        }
      }
      .code-head {
        background: #5e6074;
        border-radius: 4px 4px 0px 0px;
        padding: 8px 16px;
        font-size: 13px;
        color: #fff;
        display: flex;
        justify-content: space-between;
        align-items: center;
        // line-height: 4px;
        button {
          width: auto;
          height: auto;
          padding: 0;
          font-size: 13px;
          color: #fff;
          display: flex;
          align-items: center;
          background: none;
          border: none;
          cursor: pointer;
        }
      }
    }
  }
}
