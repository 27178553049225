.left-nav {
  width: calc(260px - var(--base-padding) * 2);
  height: calc(100% - var(--base-padding) * 2);
  background: var(--left-nav-color);
  color: var(--text-primary);
  padding: var(--base-padding);
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  gap: 10px;
  .scroll-area {
    overflow-y: auto;
    flex: 1;
  }
  .menu {
    background: none;
    color: var(--text-primary);
    :global {
      .ant-menu-item-group-title {
        padding: 8px;
        color: var(--text-light-heading);
      }
      .ant-menu-item {
        margin: 0;
        padding: 8px !important;
        margin-bottom: 5px;
        &.ant-menu-item-selected {
          background: #202123 !important;
          color: var(--text-primary) !important;
        }
        &:hover, &:active {
          background: #202123 !important;
          color: var(--text-primary) !important;
        }
      }
    }
  }
}

.settings-modal {
  :global {
    .ant-modal-content {
      background: #2f2f2f;
      padding: 0;
    }
    .ant-modal-close {
      color: #fff;
    }
  }
}
