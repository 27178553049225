.avatar {
  font-weight: 500;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  padding: 8px;
  cursor: pointer;
  transition: border-color 0.3s, background 0.3s,
    padding 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
  &:hover {
    background: #202123;
  }
  &:global(.ant-dropdown-open) {
    background: #202123;
  }
}
